import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';
import GetStarted from '../../../components/cta/GetStarted';
import Blockquote from '../../../components/frills/Blockquote';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H2 from '@rotaready/frecl/build/H2';
import H3 from '@rotaready/frecl/build/H3';
import H4 from '@rotaready/frecl/build/H4';
import Text from '@rotaready/frecl/build/Text';
import Pill from '@rotaready/frecl/build/Pill';
import Button from '@rotaready/frecl/build/Button';
import ContactForm from '../../../components/forms/ContactForm';
import OL from '@rotaready/frecl/build/OL';
import TextListItem from '@rotaready/frecl/build/TextListItem';

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    display: block;
    position: relative;
  }
`;

const HeroImageContainer = styled.div`
  height: 450px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    height: 790px;
    width: 50%;
    margin-left: auto;
    transform: translate3d(0, -80px, 0);
  }
`;

const HeroContentContainer = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: calc(100% - 80px);
  }
`;

const Hero = styled.div`
  margin: 40px 0;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin: 0;
    text-align: left;
    width: 40%;
  }
`;

const LogoContainer = styled.div`
  margin: 40px 0;

  > div {
    margin: 0 auto;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    > div {
      margin: 0;
    }
  }
`;

const StatsBox = styled.div`
  padding: 0 20px;
  box-shadow: 0 0 8px 2px rgba(71, 91, 104, 0.1);
  background-color: ${({ theme: { colors } }) => colors.grey10};
  border: 1px solid ${({ theme: { colors } }) => colors.grey20};
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  transform: translateY(-160px);
  flex-wrap: wrap;
`;

const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 200px;
  height: 80px;
  padding: 20px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 40px 0;
  }
`;

const StatHeadline = styled(H2).attrs({
  uistyle: 'brand160',
})``;

const StatSubtitle = styled(H4).attrs({
  uistyle: 'grey',
})``;

const BodyWrapper = styled.div`
  max-width: 640px;
  margin: -60px auto 0 auto;
`;

const TileTagContainer = styled.div`
  margin-bottom: 40px;

  > div {
    display: inline-block;
    margin-right: 10px;
  }
`;

const TileTag = styled(Pill).attrs({
  size: 'sm',
  uistyle: 'primary',
})``;

const Section = styled.div`
  margin-bottom: 60px;
`;

const Content = styled.div`
  margin-bottom: 30px;
`;

const TextBlock = styled(Text).attrs({
  size: 'lg',
})``;

const PopQuote = styled(H4).attrs({
  uistyle: 'primary',
  fontWeight: 600,
})`
  text-align: center;
`;

const header = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  invertColors: false,
};

class Page extends React.Component {
  render() {
    const {
      data,
    } = this.props;

    return (
      <Body header={header}>
        <SEO
          title="Fired Up Hospitality Customer Story - Rotaready"
          description="Here's how Fired Up Hospitality have been able to better deploy over 25 hours each week since using Rotaready, and achieve an ROI of 600%"
          url="customer-stories/hospitality-fired-up"
        />

        <HeroContainer>
          <HeroImageContainer>
            <GatsbyImage
              image={data.heroBackground.childImageSharp.gatsbyImageData}
              style={{ height: '100%' }}
              alt="Fired Up Hospitality food"
            />
          </HeroImageContainer>

          <HeroContentContainer>
            <ResponsiveContainer>
              <Hero>
                <TileTagContainer>
                  <TileTag text="Hospitality" />
                </TileTagContainer>

                <H3 uistyle="primary" text="Customer story" fontWeight={600} />

                <LogoContainer>
                  <GatsbyImage
                    image={data.logo.childImageSharp.gatsbyImageData}
                    style={{ height: '50px' }}
                    alt="Nusara Thai logo"
                  />
                </LogoContainer>

                <H3 uistyle="brand160" text="Here's how Fired Up Hospitality have been able to better deploy over 25 hours each week since using Rotaready, and achieve an ROI of 600%" />
              </Hero>
            </ResponsiveContainer>
          </HeroContentContainer>
        </HeroContainer>

        <ResponsiveContainer>
          <StatsBox>
            <StatItem>
              <StatHeadline text="6" />
              <StatSubtitle text="sites" />
            </StatItem>

            <StatItem>
              <StatHeadline text="125" />
              <StatSubtitle text="employees" />
            </StatItem>

            <StatItem>
              <StatHeadline text="600%" />
              <StatSubtitle text="ROI" />
            </StatItem>

            <StatItem>
              <StatHeadline text="25 hours" />
              <StatSubtitle text="better deployed each week" />
            </StatItem>
          </StatsBox>
        </ResponsiveContainer>

        <ResponsiveContainer>
          <BodyWrapper>
            <Section>
              <Content>
                <H3 uistyle="brand160" text="Fired Up Hospitality" />
              </Content>

              <Content>
                <TextBlock text="Fired Up Hospitality is a boutique hospitality group based in the south of England. Since the opening of its first restaurant Zim Braai in 2018, the restaurant group has grown significantly and now consists of five restaurants and one pub." />
              </Content>

              <Content>
                <TextBlock text="As the business expanded it became more and more difficult to track and manage costs across the sites. And a manual approach to staff scheduling and payroll was taking up hours of time, unnecessarily." />
              </Content>

              <Content>
                <Blockquote
                  text="Using spreadsheets for our staff rotas and attendance monitoring gave us very little visibility and control over our labour forecasts and costs. And it was taking me 3 days to pull together everything I needed for payroll."
                  attribution="Sophie Cox, Operations Director"
                />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Time for change" />
              </Content>

              <Content>
                <TextBlock text="So, in September 2021, the team at Fired Up Hospitality set about their search for a solution that could help improve their operational efficiency and give them greater visibility and control over costs." />
              </Content>

              <Content>
                <TextBlock text="After reviewing a number of solutions, the team decided that Rotaready was the perfect fit for the restaurant group." />
              </Content>

              <Content>
                <Blockquote
                  text="We made the move to Rotaready for a number of reasons. From an operational perspective, it ticked every box. The cost was reasonable, it integrated with our till system and I found it really easy to understand quickly after a demo. Plus the fact our account manager is super knowledgeable, easy going and understood all of the possible dilemmas I might have from day one."
                  attribution="Sophie Cox, Operations Director"
                />
              </Content>

              <Content>
                <TextBlock text="Within two weeks of making the decision to move to Rotaready, all four of Fired Up Hospitality's restaurants were up and running with new rota software." />
              </Content>

              <Content>
                <Blockquote
                  text="The onboarding process was really straightforward. After providing a few key details we were good to go. The Rotaready team handled everything for us!"
                  attribution="Sophie Cox, Operations Director"
                />
              </Content>

              <Content>
                <TextBlock text="Fired Up Hospitality have since implemented Rotaready at two new sites, Nusara - Wimborne and The Old Thatch. They have also switched on integrations between Rotaready and their EPOS and reservation systems; enhancing visibility over their costs and giving the team one source of truth." />
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline2.childImageSharp.gatsbyImageData}
                alt="Interior of a Fired Up Hospitality venue"
              />
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Fired Up's favourites" />
              </Content>

              <Content>
                <TextBlock text="Fired Up Hospitality are using all of Rotaready's features, but the restaurant group's three favourite things about the rota software are..." />
              </Content>

              <Content>
                <TextBlock>
                  <OL size="lg" uistyle="normal" iconUistyle="primary">
                    <TextListItem text="Forecasting, cost control and sign-off" />
                    <TextListItem text="Secure document signing and storage" />
                    <TextListItem text="The seamless integration between Rotaready and their EPOS solution" />
                  </OL>
                </TextBlock>
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Benefits for the entire business" />
              </Content>

              <Content>
                <TextBlock text="Since implementing Rotaready, the Fired Up Hospitality team has seen a whole host of benefits, to name a few... Day to day staff scheduling is a lot more efficient. Staff onboarding has become seamless, with the help of Rotaready's secure document signing and storage functionality. Automatic break policies have been a big time saver. And payroll is quicker and more accurate than ever, thanks to the ability to easily export payroll data from Rotaready into their payroll solution." />
              </Content>

              <Content>
                <TextBlock text="It's also given the team full visibility over costs and helped them keep on top of their labour targets - something that's been very changeable for the business over the last few years..." />
              </Content>

              <Content>
                <Blockquote
                  text="Rotaready has really helped our team stay on top of their labour targets. Our managers are able to keep an eye on costs throughout the week, which has meant we have been able to be consistent in our wage costs, and avoid haemorrhaging anywhere. I really don't think we could have achieved this without Rotaready!"
                  attribution="Sophie Cox, Operations Director"
                />
              </Content>
            </Section>

            <Section>
              <PopQuote text={`A combination of all of these benefits is saving the team at Fired Up Hospitality over 25 hours a week and has helped them achieve an ROI of 600%!`} />
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline1.childImageSharp.gatsbyImageData}
                alt="Bowls of Thai curry and rice"
              />
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="What's next?" />
              </Content>

              <Content>
                <TextBlock text="Rotaready's customer success team are continuing to work closely with Fired Up Hospitality to help the team leverage all the benefits of the platform and ensure the smooth rollout of any future sites." />
              </Content>

              <Content>
                <Blockquote
                  text="I'd highly recommend Rotaready to any other hospitality business. Functionally it does everything we need it to, but the support is five star. 9 times out of 10 my questions are answered instantly via live chat, and nothing has ever taken more than an hour or so to be resolved."
                  attribution="Sophie Cox, Operations Director"
                />
              </Content>
            </Section>

            <Section>
              <ContactForm
                title="Download the story"
                submitLabel="Download PDF"
                source="customerstories_fireduphospitality"
                label="rotaready.com/customer-stories/hospitality-fired-up"
                checkboxes={[
                  {
                    id: 'marketing',
                    text: 'I\'d like to receive updates, offers and new content from Rotaready by email. You can unsubscribe at any time.',
                    required: false,
                    initial: false,
                  },
                ]}
                onSuccess={(
                  <>
                    <TextBlock>
                      <H3 uistyle="brand160" text="Happy reading 📚" />
                    </TextBlock>

                    <TextBlock>
                      <Text size="lg" text="Thank you for downloading this customer story! Click below to get the file." />
                    </TextBlock>

                    <TextBlock>
                      <a href="/files-static/customer-stories/Rotaready-Story-Fired-Up-Hospitality.pdf" download>
                        <Button uistyle="primary" text="Download PDF" size="md" />
                      </a>
                    </TextBlock>
                  </>
                )}
              />
            </Section>
          </BodyWrapper>
        </ResponsiveContainer>

        <GetStarted />
      </Body>
    );
  }
}

export default Page;

export const query = graphql`
  query {
    heroBackground: file(relativePath: { eq: "customer-stories/fired-up-hospitality/header.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 50
          transformOptions: { cropFocus: CENTER, fit: COVER }
        )
      }
    }

    logo: file(relativePath: { eq: "customer-stories/fired-up-hospitality/logo.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 50, quality: 100)
      }
    }

    inline1: file(relativePath: { eq: "customer-stories/fired-up-hospitality/inline1.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }

    inline2: file(relativePath: { eq: "customer-stories/fired-up-hospitality/inline2.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }
  }
`;
